import React from "react";
import TagItem from "../TagItem";

const TagFilter = ( props ) => (
  <div className={'tag-filter'}>
    { props.tagItems.map( tag => <TagItem value={ tag } checked={ props.tagFilter.indexOf(tag) > -1 } toggleFilterFunc={ props.toggleFilterFunc } key={tag} /> ) }
  </div>
);

export default TagFilter;

// import React from "react";
// import TagItem from "../TagItem";
//
// export default class TagFilter extends React.Component {
//
//   render() {
//
//     let tagItems = [];
//
//     this.props.tagItems.forEach( tag => {
//       tagItems.push(<TagItem value={ tag } checked={ this.props.tagFilter.indexOf(tag) > -1 } toggleFilterFunc={ this.props.toggleFilterFunc } key={tag} />);
//     });
//
//     return (
//       <div className={'tag-filter'}>
//         { this.props.tagItems.map( tag => <TagItem value={ tag } checked={ this.props.tagFilter.indexOf(tag) > -1 } toggleFilterFunc={ this.props.toggleFilterFunc } key={tag} /> ) }
//       </div>
//     );
//   }
// }
