import React from "react";
import MasonryItem from "../MasonryItem";
import Masonry from 'react-masonry-css'

export default class MasonryList extends React.Component {

  constructor(props) {
    super(props);

    this.breakpointColumnsObj = {
      default: 3,
      991: 2,
      575: 1
    };
  }

  render() {

    let masonryItems = [];

    const keys = Object.keys(this.props.items);
    for (const key of keys) {
      const item = this.props.items[key];
      if(item.visible) {
        masonryItems.push(<MasonryItem {...item} key={item.name} toggleFilterFunc={this.props.toggleFilterFunc} visible={item.visible}/>);
      }
    }

    return (
      <Masonry breakpointCols={ this.breakpointColumnsObj } className="masonry-list" columnClassName="masonry-list-grid-column">
        { masonryItems }
      </Masonry>
    );
  }

}
