import React from "react";

import { pickColor as randomColor } from "../../Utils"; // *
// import { pastel_colour as randomColor } from "../../Utils"; // *
// import { colorize as randomColor } from "../../Utils"; // ?
// import { hashCode as randomColor } from "../../Utils";
// import { colortest as randomColor } from "../../Utils";
// import { stringToColor as randomColor } from "../../Utils";
// import { kolorka as randomColor } from "../../Utils"; // BAAAD

export default class TagItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {color: randomColor(this.props.value)}
  }

  render() {
    return (
      <label className={'tag-item'}>
        <input type={'checkbox'} checked={ this.props.checked } onChange={ this.props.toggleFilterFunc.bind(this, this.props.value) }/>
        <span className={'tag-item-inner'} style={{backgroundColor: this.state.color}}>{this.props.value}</span>
      </label>
    );
  }
}
