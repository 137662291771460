import React from "react";

export default class SearchForm extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleChange(event) {
    this.props.updateValue(event.target.value);
  }

  handleKeyUp(event) {
    if(event.key === 'Escape') {
      this.props.updateValue('');
    }
  }

  static handleSubmit(event) {
    event.preventDefault();
    return false;
  }

  render() {
    return (
      <form onSubmit={ SearchForm.handleSubmit } className="App-search-form">
        <input autoFocus={true} type="text" value={this.props.value} onChange={this.handleChange} onKeyUp={ this.handleKeyUp } placeholder={'Search'} />
      </form>
    );
  }
}
