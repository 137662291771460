import React from "react";

import marked from 'marked';

import TagFilter from "../TagFilter";

export default class MasonryItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = { flipped: false };

    this.flipCard = this.flipCard.bind(this);
  }

  flipCard(e) {
    e.preventDefault();
    this.setState({ flipped: !this.state.flipped });
  }

  render() {
    return (
      <div className={ 'masonry-item' + (!this.props.visible ? ' hidden' : '') + (this.state.flipped ? ' flipped' : '') } >
        <div className={ 'masonry-item-inner' }>
          <div className={ 'masonry-item-front' }>
            <h3 className={ 'masonry-item-title' }><a href={ this.props.links.production } target={'_blank'} rel="noopener noreferrer">{ this.props.name } <span className={'symbol-link'}/></a></h3>
            <p className={ 'masonry-item-abstract' }>{ this.props.abstract }{ this.props.description && this.props.description !== this.props.abstract ? <button className={'button-link'} type={'button'} onClick={ this.flipCard } >[...]</button> : '' }</p>
            <TagFilter tagItems={ this.props.tags } tagFilter={ this.props.tags } toggleFilterFunc={ this.props.toggleFilterFunc } />
          </div>
          <div className={ 'masonry-item-back' } onClick={ this.props.description && this.flipCard }>
            {this.props.description &&
              <p className={'masonry-item-abstract'} dangerouslySetInnerHTML={{ __html: marked(this.props.description) }} />
            }
          </div>
        </div>
      </div>
    );
  }
}
