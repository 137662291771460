import React from 'react';
import ReactGA from 'react-ga';
import './App.scss';

import LogoEman from './Assets/images/logo-eman.svg';
import IconFacebook from './Assets/images/ic-facebook.svg';
import IconTwitter from './Assets/images/ic-twitter.svg';
import IconLinkedin from './Assets/images/ic-linkedin.svg';
import IconYoutube from './Assets/images/ic-youtube.svg';
import IconInstagram from './Assets/images/ic-instagram.svg';
import IconDribble from './Assets/images/ic-dribbble.svg';

import SearchForm from './Components/SearchForm';
import TagFilter from "./Components/TagFilter";
import MasonryList from "./Components/MasonryList";

import { accentFold } from './Utils';

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // authenticated: true,
      initialized: false,
      appData: {},
      tags: [],
      searchMap: {},
      filterText: '',
      filterTags: []
    };

    this.updateFilterText = this.updateFilterText.bind(this);
    // this.toggleFilterTag = this.toggleFilterTag.bind(this);
    this.setFilterTag = this.setFilterTag.bind(this);
    this.filterItems = this.filterItems.bind(this);
  }

  componentDidMount() {
    document.title = window['eman-signpost-config'].AppTitle;

    if(window['eman-signpost-config'].GAUA && window['eman-signpost-config'].GAUA.length) {
      ReactGA.initialize(window['eman-signpost-config'].GAUA);
    }

    this.fetchDataFromApi();
  }

  /* ******************************************************* */

  // This method extracts all possible tags without duplicates
  parseTags(data) {
    let tagsArray = [];
    Object.entries(data).forEach(([key, value]) => {
      tagsArray = [...new Set([...tagsArray, ...value.tags])];
    });
    return tagsArray;
  }

  // This methods creates searchMap
  createSearchMap(data) {
    let searchMap = new Map();

    let propsOfInterest = ['name', 'abstract', 'description'];
    Object.entries(data).forEach(([entryKey, entryValue]) => {

      // create array of possible searchable words for current entry
      let entryWordList = [];
      propsOfInterest.forEach((propName) => {
        if(entryValue.hasOwnProperty(propName)) {
          let entryWords = entryValue[propName].replace(/['",.`()[\]{}]/g, '').replace(/\s{2,}/g, ' ').toLowerCase().split(' '); // replace unwanted characters, punctation, whitespace and split to array
          entryWords = entryWords.concat( accentFold(entryValue[propName]).replace(/['",.`()[\]{}]/g, '').replace(/\s{2,}/g, ' ').toLowerCase().split(' ') ); // same as above, but without diacritics
          entryWords.push(entryValue[propName]); // add original prop value/content (to be possibly copy&paste working ;) )
          entryWords = entryWords.filter((word) => word.length > 2 ); // filter-out words shorter than 3 characters
          entryWordList = [...new Set([...entryWordList, ...entryWords])]; // make wordlist unique
        }
      });

      // add keywords if exists
      if(entryValue.hasOwnProperty('keywords')) {
        entryWordList = [...new Set([...entryWordList, ...entryValue.keywords, ...entryValue.keywords.map(word => accentFold(word)) ])]; // add keywords if exists
      }

      // add responsibles if exists
      if(entryValue.hasOwnProperty('responsible')) {
        if(entryValue.responsible.hasOwnProperty('pm')) {
          entryWordList = [...new Set([...entryWordList, entryValue.responsible.pm, entryValue.responsible.pm + '@eman.cz', ...entryValue.responsible.pm.split('.') ])]; // add keywords if exists
        }
      }

      // console.log(entryWordList);

      // add each word in wordlist to searchmap with it's corresponding parent ID
      entryWordList.forEach((word) => {

        if(!searchMap.has(word)) {
          searchMap.set(word, [entryKey]);
        } else {
          searchMap.set(word, searchMap.get(word).concat([entryKey]));
        }

        // searchMap.set(word, ( !searchMap.has(word) ? [entryKey] : searchMap.get(word).concat([entryKey]) ) );

        // Or DO get FIRST - If OK -> use got reference ... else create new via SET ...
      });

    });
    return searchMap;
  }

  // Fetches data from API and convert them into JSON object
  async fetchDataFromApi() {
    fetch(process.env.NODE_ENV === 'production' ? 'index.json' : `index.${process.env.NODE_ENV}.json`)
      .then(response => response.json())
      .then(data => {
        const tags = this.parseTags(data);

        // add "visible" attribute to each record
        Object.keys(data).forEach(function(key) {
          data[key].visible = true;
        });

        this.setState({ appData: data, tags: tags, filterTags: tags, searchMap: this.createSearchMap(data), initialized: true }, () => this.filterItems() )
      });
  }

  // Updates filter text/value in application state
  updateFilterText(newValue) {
    this.setState({filterText: newValue, filterTags: this.state.tags}, () => this.filterItems() );
  }

  // Toggles single filter text/value in application state
  // toggleFilterTag(filterValue) {
  //
  //   let newFilter = this.state.filterTags.slice();
  //   var currentValueIndex = newFilter.indexOf(filterValue);
  //
  //   if (currentValueIndex > -1) {
  //     newFilter.splice(currentValueIndex, 1);
  //   } else {
  //     newFilter.push(filterValue);
  //   }
  //
  //   // If no filter is present - force all tags active
  //   if(newFilter.length === 0) {
  //     newFilter = this.state.tags;
  //   }
  //
  //   this.setState({filterTags: newFilter}, () => this.filterItems() );
  // }

  // Sets single filter text/value in application state
  setFilterTag(filterValue) {

    let newFilter;
    if(`${this.state.filterTags}` === `${[filterValue]}`) {
      newFilter = this.state.tags;
    } else {
      newFilter = [filterValue];
    }

    this.setState({filterTags: newFilter}, () => this.filterItems() );
  }

  filterItems() {
    let _this = this;
    let data = Object.assign({}, this.state.appData);

    // console.log(this.state.searchMap.keys());

    Object.keys(data).forEach(function(key) {
      // item is not visible by default ... if matches any active tag - make it visible
      data[key].visible = false;
      data[key].tags.forEach((tag) => {
        if( !data[key].visible) {
          data[key].visible = _this.state.filterTags.indexOf(tag) > -1;

          // If item should be visible because of matching tag -
          // and text search filter is not empty ...
          const filterText = _this.state.filterText.trim().toLowerCase();
          if(data[key].visible && filterText !== '') {

            // Disable an item visibility and try to match item also via fulltext
            data[key].visible = false;
            var resultingItems = [];
            _this.state.searchMap.forEach((val,index) => {
              // If current searchMap item matches (at least partially) searched text add id's to the list
              if(index.startsWith(filterText)) {
                resultingItems = resultingItems.concat(val);
              }
            });
            // If we found something at current loop - make currently iterated item visible
            if(resultingItems.length > 0 && resultingItems.indexOf(key) > -1) {
              data[key].visible = true;
            }
          }
        }
      });
    });

    this.setState({appData: data});
  }

  render() {
    return (
      this.state.initialized ? (
      <div className="App">
        <div className="App-container">
          <header className="App-header">
            <a href="https://www.emanprague.com/" target="_blank" rel="noopener noreferrer">
              <img className="App-logo" src={ LogoEman } alt="eMan logo" />
            </a>
            <h1 className="App-title">{ window['eman-signpost-config'].AppTitle }</h1>
            { window['eman-signpost-config'].AppSubTitle && <h2 className="App-subtitle">{ window['eman-signpost-config'].AppSubTitle }</h2> }
          </header>
          <SearchForm value={ this.state.filterText } updateValue={ this.updateFilterText } />
          <TagFilter tagItems={ this.state.tags } toggleFilterFunc={ this.setFilterTag } tagFilter={ this.state.filterTags } />
          <MasonryList items={ this.state.appData } toggleFilterFunc={ this.setFilterTag } />
        </div>
        <footer className="App-footer">
          { window['eman-signpost-config'].FooterClaim && <h3 className="App-footer-note">{ window['eman-signpost-config'].FooterClaim }</h3> }
          <a href="https://www.emanprague.com/" target="_blank" rel="noopener noreferrer">
            <img className="App-logo" src={ LogoEman } alt="eMan logo" />
          </a>
          <div>
            <span>eMan s.r.o.</span><br />
            <a href="mailto:info@emanprague.com">info@emanprague.com</a>
          </div>
          <div className="App-social">
            <a href="https://facebook.com/emanprague" target="_blank" rel="noopener noreferrer"><img className="icon-social" alt="Facebook logo" src={ IconFacebook } /></a>
            <a href="https://twitter.com/emanprague" target="_blank" rel="noopener noreferrer"><img className="icon-social" alt="Twitter logo" src={ IconTwitter } /></a>
            <a href="https://www.linkedin.com/company/eman-s-r-o-/" target="_blank" rel="noopener noreferrer"><img className="icon-social" alt="Linked logo" src={ IconLinkedin } /></a>
            <a href="https://www.youtube.com/channel/UC9c_tJacvkv_2l_8tvsmGhA" target="_blank" rel="noopener noreferrer"><img className="icon-social" alt="Youtube logo" src={ IconYoutube } /></a>
            <a href="https://www.instagram.com/emanprague/" target="_blank" rel="noopener noreferrer"><img className="icon-social" alt="Instagram logo" src={ IconInstagram } /></a>
            <a href="https://dribbble.com/emanprague" target="_blank" rel="noopener noreferrer"><img className="icon-social" alt="Dribble logo" src={ IconDribble } /></a>
          </div>
          <div className="App-copyright">Copyright © eMan s.r.o. { new Date().getFullYear() }</div>
        </footer>
      </div>
    ) : (
        <span className="text-loader"/>
      )
    )
  }
}
